<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form @submit="onSubmit">
                    <b-col cols="12" class="mt-16">
                        <b-row>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <b-form-group id="input-group-2" label="รหัสบทความ:" label-for="input-2">
                                    <b-form-input :type="`text`" v-model="articleCode" required
                                        :disabled="true"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <b-form-group id="input-group-2" label="วันที่แจ้งผลกลั่นกรองต้นฉบับเบื้องต้น:"
                                    label-for="input-2">
                                    <b-form-input :id="`type-date`" :type="`date`" v-model="assessmentDate" required
                                        :disabled="true"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                <b-form-group id="input-group-2" label="รหัสบทความ:" label-for="input-2">
                                    <b-form-input v-model="articleName" id="input-2" :disabled="true"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-col cols="12" class="mt-16">
                            <b-row class="mt-0">
                                <b-col>
                                    <H4>1.เกณฑ์ขอบเขตเนื้อหาบทความ</H4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <table class="table table-bordered">
                                        <tr v-for="item in form.form1" :key="item.id">
                                            <td style="width: 97%;">
                                                <div class="container-content-form"
                                                    v-bind:style="[scopeIdSelected == item.id ? { 'display': 'block' } : { 'display': 'flex' }]">
                                                    <h5
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        {{ item.content }}</h5>
                                                    <b-form-group>
                                                        <b-form-input v-model="item.content" id="input-2"
                                                            :placeholder="item.content" required
                                                            v-if="scopeIdSelected == item.id"></b-form-input>
                                                    </b-form-group>
                                                    &nbsp;
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="updateScopeContentText(item.id)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-edit-fill"></i>
                                                    </span>
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="saveScopeContentText(item.id, item.content)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': '' } : { 'display': 'none' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-save-3-fill"></i>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <b-form-radio v-model="item.option" name="form1"
                                                    :value="true"
                                                    required></b-form-radio>
                                            </td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" class="mt-16 ">
                            <b-row class="mt-0">
                                <b-col>
                                    <H4>2.เกณฑ์ขอบเขตเนื้อหาบทความ</H4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <table class="table table-bordered">
                                        <tr v-for="item, index in form.form2" :key="index">
                                            <td style="width: 60%;">
                                                <div class="container-content-form"
                                                    v-bind:style="[scopeIdSelected == item.id ? { 'display': 'block' } : { 'display': 'flex' }]">
                                                    <h5
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        2.{{ index + 1 }} {{ item.content }}</h5>
                                                    <b-form-group>
                                                        <b-form-input v-model="item.content" id="input-2"
                                                            :placeholder="item.content" required
                                                            v-if="scopeIdSelected == item.id"></b-form-input>
                                                    </b-form-group>
                                                    &nbsp;
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="updateScopeContentText(item.id)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-edit-fill"></i>
                                                    </span>
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="saveScopeContentText(item.id, item.content)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': '' } : { 'display': 'none' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-save-3-fill"></i>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <b-form-group label="">
                                                    <b-form-radio v-model="item.option" :name="item.id" :value="true"
                                                        required>ผ่าน</b-form-radio>
                                                    <b-form-radio v-model="item.option" :name="item.id" :value="false"
                                                        required>ไม่ผ่าน</b-form-radio>
                                                </b-form-group>
                                            </td>
                                            <td>
                                                <b-form-group id="input-group-2" label="" label-for="input-2">
                                                    <b-form-input v-model="item.sugguest" id="input-2"
                                                        placeholder="ข้อเสนอแนะ"></b-form-input>
                                                </b-form-group>
                                            </td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" class="mt-16 ">
                            <b-row class="mt-0">
                                <b-col>
                                    <H4>3.เกณฑ์รูปแบบการพิมพ์และการอ้างอิง</H4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <table class="table table-bordered">
                                        <tr v-for="item, index in form.form3" :key="index" v-if="index < 3">
                                            <td style="width: 60%;">
                                                <div class="container-content-form"
                                                    v-bind:style="[scopeIdSelected == item.id ? { 'display': 'block' } : { 'display': 'flex' }]">
                                                    <h5
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        3.{{ index + 1 }} {{ item.content }}</h5>
                                                    <b-form-group>
                                                        <b-form-input v-model="item.content" id="input-2"
                                                            :placeholder="item.content" required
                                                            v-if="scopeIdSelected == item.id"></b-form-input>
                                                    </b-form-group>
                                                    &nbsp;
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="updateScopeContentText(item.id)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-edit-fill"></i>
                                                    </span>
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="saveScopeContentText(item.id, item.content)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': '' } : { 'display': 'none' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-save-3-fill"></i>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <b-form-group label="">
                                                    <b-form-radio v-model="item.option" :name="item.id" :value="true"
                                                        required>ผ่าน</b-form-radio>
                                                    <b-form-radio v-model="item.option" :name="item.id" :value="false"
                                                        required>ไม่ผ่าน</b-form-radio>
                                                </b-form-group>

                                            </td>
                                            <td>
                                                <b-form-group id="input-group-2" label="" label-for="input-2">
                                                    <b-form-input v-model="item.sugguest" id="input-2"
                                                        placeholder="ข้อเสนอแนะ"></b-form-input>
                                                </b-form-group>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3">
                                                <table class="table table-bordered">
                                                    <tr>
                                                        <th>บทความวิจัย</th>
                                                        <th>บทความวิชาการ</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <ul v-for="subItem1, index in form.form31" :key="index">
                                                                <li>
                                                                    <b-form-checkbox v-model="subItem1.isChecked"
                                                                        :name="subItem1.id">
                                                                        {{ subItem1.content }}
                                                                    </b-form-checkbox>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <ul v-for="subItem2, index in form.form32" :key="index">
                                                                <li>
                                                                    <b-form-checkbox v-model="subItem2.isChecked"
                                                                        :name="subItem2.id">
                                                                        {{ subItem2.content }}
                                                                    </b-form-checkbox>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>

                                        <tr v-for="item, index in form.form3" :key="index" v-if="index > 2">
                                            <td style="width: 60%;">
                                                <div class="container-content-form"
                                                    v-bind:style="[scopeIdSelected == item.id ? { 'display': 'block' } : { 'display': 'flex' }]">
                                                    <h5
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        3.{{ index + 1 }} {{ item.content }}</h5>
                                                    <b-form-group>
                                                        <b-form-input v-model="item.content" id="input-2"
                                                            :placeholder="item.content" required
                                                            v-if="scopeIdSelected == item.id"></b-form-input>
                                                    </b-form-group>
                                                    &nbsp;
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="updateScopeContentText(item.id)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-edit-fill"></i>
                                                    </span>
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="saveScopeContentText(item.id, item.content)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': '' } : { 'display': 'none' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-save-3-fill"></i>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                    <b-form-radio v-model="item.option"
                                                        :aria-describedby="ariaDescribedby" :name="item.id"
                                                        :value="true">ผ่าน</b-form-radio>
                                                    <b-form-radio v-model="item.option"
                                                        :aria-describedby="ariaDescribedby" :name="item.id"
                                                        :value="false">ไม่ผ่าน</b-form-radio>
                                                </b-form-group>

                                            </td>
                                            <td>
                                                <b-form-group id="input-group-2" label="" label-for="input-2">
                                                    <b-form-input v-model="item.sugguest" id="input-2"
                                                        placeholder="ข้อเสนอแนะ"></b-form-input>
                                                </b-form-group>
                                            </td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" class="mt-16 ">
                            <b-row class="mt-0">
                                <b-col>
                                    <H4>4.เกณฑ์เนื้อหาในบทคัดย่อและ Abstract</H4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <table class="table table-bordered">
                                        <tr v-for="item, index in form.form4" :key="index">
                                            <td style="width: 60%;">
                                                <div class="container-content-form"
                                                    v-bind:style="[scopeIdSelected == item.id ? { 'display': 'block' } : { 'display': 'flex' }]">
                                                    <h5
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        4.{{ index + 1 }} {{ item.content }}</h5>
                                                    <b-form-group>
                                                        <b-form-input v-model="item.content" id="input-2"
                                                            :placeholder="item.content" required
                                                            v-if="scopeIdSelected == item.id"></b-form-input>
                                                    </b-form-group>
                                                    &nbsp;
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="updateScopeContentText(item.id)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-edit-fill"></i>
                                                    </span>
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="saveScopeContentText(item.id, item.content)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': '' } : { 'display': 'none' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-save-3-fill"></i>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <b-form-group label="">
                                                    <b-form-radio v-model="item.option" :name="item.id" :value="true"
                                                        required>ผ่าน</b-form-radio>
                                                    <b-form-radio v-model="item.option" :name="item.id" :value="false"
                                                        required>ไม่ผ่าน</b-form-radio>
                                                </b-form-group>

                                            </td>
                                            <td>
                                                <b-form-group id="input-group-2" label="" label-for="input-2">
                                                    <b-form-input v-model="item.sugguest" id="input-2"
                                                        placeholder="ข้อเสนอแนะ"></b-form-input>
                                                </b-form-group>
                                            </td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" class="mt-16 ">
                            <b-row class="mt-0">
                                <b-col>
                                    <H4>5. เกณฑ์คุณภาพเนื้อหาในบทความ</H4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <table class="table table-bordered">
                                        <tr v-for="item, index in form.form5" :key="index">
                                            <td style="width: 60%;">
                                                <div class="container-content-form"
                                                    v-bind:style="[scopeIdSelected == item.id ? { 'display': 'block' } : { 'display': 'flex' }]">
                                                    <h5
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        5.{{ index + 1 }} {{ item.content }}</h5>
                                                    <b-form-group>
                                                        <b-form-input v-model="item.content" id="input-2"
                                                            :placeholder="item.content" required
                                                            v-if="scopeIdSelected == item.id"></b-form-input>
                                                    </b-form-group>
                                                    &nbsp;
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="updateScopeContentText(item.id)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-edit-fill"></i>
                                                    </span>
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="saveScopeContentText(item.id, item.content)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': '' } : { 'display': 'none' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-save-3-fill"></i>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <b-form-group label="">
                                                    <b-form-radio v-model="item.option" :name="item.id" :value="true"
                                                        required>ผ่าน</b-form-radio>
                                                    <b-form-radio v-model="item.option" :name="item.id" :value="false"
                                                        required>ไม่ผ่าน</b-form-radio>
                                                </b-form-group>

                                            </td>
                                            <td>
                                                <b-form-group id="input-group-2" label="" label-for="input-2">
                                                    <b-form-input v-model="item.sugguest" id="input-2"
                                                        placeholder="ข้อเสนอแนะ"></b-form-input>
                                                </b-form-group>
                                            </td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" class="mt-16">
                            <b-row class="mt-0">
                                <b-col>
                                    <H4> พยากรณ์ผลประเมินจากผู้ทรงคุณวุฒิประจำสาขา</H4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <table class="table table-bordered">
                                        <tr v-for="item in form.form6" :key="item.id">
                                            <td style="width: 98%;">
                                                <div class="container-content-form"
                                                    v-bind:style="[scopeIdSelected == item.id ? { 'display': 'block' } : { 'display': 'flex' }]">
                                                    <h5
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        {{ item.content }}</h5>
                                                    <b-form-group>
                                                        <b-form-input v-model="item.content" id="input-2"
                                                            :placeholder="item.content" required
                                                            v-if="scopeIdSelected == item.id"></b-form-input>
                                                    </b-form-group>
                                                    &nbsp;
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="updateScopeContentText(item.id)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-edit-fill"></i>
                                                    </span>
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="saveScopeContentText(item.id, item.content)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': '' } : { 'display': 'none' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-save-3-fill"></i>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <b-form-radio v-model="item.option" name="form6" :value="true"
                                                    required></b-form-radio>
                                            </td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" class="mt-16">
                            <b-row class="mt-0">
                                <b-col>
                                    <H4> สรุปผลการกลั่นกรองต้นฉบับบทความวิจัยเบื้องต้นโดยกองบรรณาธิการ</H4>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <table class="table table-bordered">
                                        <tr v-for="item in form.form7" :key="item.id">
                                            <td style="width: 97%;">
                                                <div class="container-content-form"
                                                    v-bind:style="[scopeIdSelected == item.id ? { 'display': 'block' } : { 'display': 'flex' }]">
                                                    <h5
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        {{ item.content }}</h5>
                                                    <b-form-group>
                                                        <b-form-input v-model="item.content" id="input-2"
                                                            :placeholder="item.content" required
                                                            v-if="scopeIdSelected == item.id"></b-form-input>
                                                    </b-form-group>
                                                    &nbsp;
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="updateScopeContentText(item.id)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': 'none' } : { 'display': '' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-edit-fill"></i>
                                                    </span>
                                                    <span class="update-content-form hp-d-block hp-input-description"
                                                        v-on:click="saveScopeContentText(item.id, item.content)"
                                                        v-bind:style="[scopeIdSelected == item.id ? { 'display': '' } : { 'display': 'none' }]">
                                                        <i class="hp-text-color-dark-0 ri-2x ri-save-3-fill"></i>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <b-form-radio v-model="item.option" name="form7" :value="true"
                                                    required></b-form-radio>
                                            </td>
                                        </tr>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-col>





                    </b-col>

                    <div class="submit-container">
                        <b-button type="submit" variant="outline-black-100">บันทึก</b-button>
                    </div>
                </b-form>
            </b-card>
        </div>
        <!-- Author Modal -->


    </b-row>


</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BAlert,
    BSpinner,
    BModal,
    BTable,
    BBreadcrumb,
    BFormRadio,
    BFormRadioGroup,
} from "bootstrap-vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import moment from "moment-timezone";
import { generateUUIDV4 } from "../../../../../helpers/validation";
import articleService from "../../../../../services/article.service";
import formService from "../../../../../services/form.service";

const downloadUrl = process.env.VUE_APP_DOWNLOADURL

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        BTable,
        PageTitle,
        BFormRadio,
        BFormRadioGroup
    },
    data() {
        return {
            breadcrumbItems: [{
                text: "ตารางแบบฟอร์มกลั่นกรองต้นฉบับ",
                href: "#",
            },
            {
                text: "แบบฟอร์มกลั่นกรองต้นฉบับ",
                to: {
                    name: "article-form"
                },
            },
            {
                text: "แก้ไขแบบฟอร์มกลั่นกรองต้นฉบับ",
                active: true,
            },
            ],
            show: true,
            role: null,
            isLoading: false,
            errors: [],
            timeZone: "Asia/Bangkok",
            isDisabledButton: true,
            dateNow: new Date(),
            articleCode: null,
            articleSelected: null,
            assessmentDate: null,
            articles: [],
            articleName: null,
            scopeOfContents: [],
            headVariant: null,
            scopeIdSelected: null,
            form: {
                articleId: null,
                form1: [],
                form2: [],
                form3: [],
                form31: [],
                form32: [],
                form4: [],
                form5: [],
                form6: [],
                form7: []
            },
            formResponse: {
                articleId: null,
                form1: [],
                form2: [],
                form3: [],
                form31: [],
                form32: [],
                form4: [],
                form5: [],
                form6: [],
                form7: []
            },



        };
    },
    computed: {

    },
    methods: {
        getAssessmentForm: async function (articleId) {
            let response = await formService.getInitialAssessmentForm();
            let assessmentsForms = response.data.initial_assessment_form;
            let assessmentForm = assessmentsForms.find(x => x.article_id == articleId);
            if (assessmentForm) {
                this.form.articleId = articleId;
                this.assessmentDate = moment(assessmentForm.assessment_date).tz(this.timeZone).format('yyyy-MM-DD');
                this.articleName = assessmentForm.name_th + " " + `(${assessmentForm.name_eng})`;
                this.articleCode = assessmentForm.article_code;
            }
        },
        getScopeOfContents: async function () {
            let response = await formService.getScopeOfContents();
            let scopeContents = response.data.scope_of_contents;

            let responseDetails = await formService.getDetailsIASFFormById(this.articleId);
            let scopeContentsDetails = responseDetails.data.form;

            scopeContents.forEach((item) => {
                if (item.form_section_id == "5e00bdd6-e44f-440b-8d95-4916bcdfc3d5") {
                    let form1 = scopeContentsDetails.form1.find(x=> x.form_scope_of_content_id == item.id);
                    console.log("form1", form1,scopeContentsDetails.form1,item.id );
                    let newR = {
                        id: item.id,
                        option: form1? true : false,
                        form_section_id: item.form_section_id,
                        content: item.scope_content
                    }

                    this.form.form1.push(newR);
                }
                if (item.form_section_id == "43cb4972-b89f-412f-9394-1166d73da2e8") {
                    let form2 = scopeContentsDetails.form2.find(x=> x.form_scope_of_content_id == item.id);
                    let newR = {
                        id: item.id,
                        option: form2.is_passed == "1" ? true : false,
                        sugguest: form2.suggestions?? null,
                        form_section_id: item.form_section_id,
                        content: item.scope_content
                    }
                    this.form.form2.push(newR);
                }
                if (item.form_section_id == "d674318c-9114-4486-8632-e486f9568d17") {
                    let getForm3 = scopeContentsDetails.form3.find(x=> x.form_scope_of_content_id == item.id);
                    console.log("form3" , item.id,scopeContentsDetails.form3);
                    let newR = {
                        id: item.id,
                        option: getForm3.is_passed == "1" ? true : false,
                        sugguest: getForm3.suggestions?? null,
                        form_section_id: item.form_section_id,
                        content: item.scope_content
                    }

                    this.form.form3.push(newR);
                }
                if (item.form_section_id == "d64f9bf1-5b03-4db3-b5b3-290f315fd720") {
                    let getForm31 = scopeContentsDetails.form31.find(x=> x.form_scope_of_content_id == item.id);
                    let newR = {
                        id: item.id,
                        content: item.scope_content,
                        form_section_id: item.form_section_id,
                        isChecked: getForm31.is_passed == "1" ? true : false,
                    }

                    this.form.form31.push(newR);
                }
                if (item.form_section_id == "c194c097-3e77-42df-a5d6-ebffbba602ba") {
                    let getForm32 = scopeContentsDetails.form32.find(x=> x.form_scope_of_content_id == item.id);
                    let newR = {
                        id: item.id,
                        content: item.scope_content,
                        form_section_id: item.form_section_id,
                        isChecked:  getForm32.is_passed == "1" ? true : false,
                    }

                    this.form.form32.push(newR);
                }
                if (item.form_section_id == "222199a4-203a-4a06-94de-312d739d8c06") {
                    let getForm4 = scopeContentsDetails.form4.find(x=> x.form_scope_of_content_id == item.id);
                   
                    let newR = {
                        id: item.id,
                        option: getForm4.is_passed == "1" ? true : false,
                        sugguest: getForm4.suggestions?? null,
                        form_section_id: item.form_section_id,
                        content: item.scope_content
                    }

                    this.form.form4.push(newR);
                }
                if (item.form_section_id == "d2fcecbb-93f1-44aa-bbfd-c6aebd1429a8") {
                    let getForm5 = scopeContentsDetails.form5.find(x=> x.form_scope_of_content_id == item.id);
                    let newR = {
                        id: item.id,
                        option: getForm5.is_passed == "1" ? true : false,
                        sugguest: getForm5.suggestions?? null,
                        form_section_id: item.form_section_id,
                        content: item.scope_content
                    }

                    this.form.form5.push(newR);
                }

                if (item.form_section_id == "3d8ee212-0f12-4b77-91ae-fdb9ea173a6a") {
                    let getForm6 = scopeContentsDetails.form6.find(x=> x.form_scope_of_content_id == item.id);
                   
                    let newR = {
                        id: item.id,
                        option: getForm6? true : false,
                        form_section_id: item.form_section_id,
                        content: item.scope_content
                    }

                    this.form.form6.push(newR);
                }
                if (item.form_section_id == "c239459d-8bdb-4a2c-af1e-2dc5332e2475") {
                    let getForm7 = scopeContentsDetails.form7.find(x=> x.form_scope_of_content_id == item.id);
                    console.log("getForm7", getForm7,scopeContentsDetails.form7,item.id);
                    let newR = {
                        id: item.id,
                        option: getForm7? true : false,
                        form_section_id: item.form_section_id,
                        content: item.scope_content
                    }

                    this.form.form7.push(newR);
                }
            })
        },
        updateScopeContentText(scopeId) {
            this.scopeIdSelected = scopeId;
            console.log("scopeId", scopeId)
        },
        saveScopeContentText: async function (id, scopeContent) {
            let newR = { id: id, scope_content: scopeContent }
            await formService.updateScopeOfContents(newR);
            this.scopeIdSelected = null;
        },
        onSubmit: async function (event) {
            event.preventDefault();
            console.log("submit", this.form)
            await formService.updateIASFForm(this.form);
            history.back();
            console.log(this.form)
        },
    },
    mounted() {
        this.articleId = this.$route.params.id;
        this.getAssessmentForm(this.articleId);
        this.getScopeOfContents();
       // this.getScopeOfContentsById(this.articleId);
        
        console.log(process.env.VUE_APP_APIURL)
    }

};
</script>
